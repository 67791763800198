<script>

import moment from "moment"
import DatePicker from "vue2-datepicker";
import Vue from "vue";

export default {
  page: {title: 'Στατιστικά πελατών'},
  components:{DatePicker},
  data() {
      return {
          renderGraphs: false,
          pieOptions:{
              chart: {width: 250, type: 'pie',},
              labels: ['Άρρεν', 'Θήλυ'],
              colors:['#3155A6','#FFC0CB'],
              tooltip:{y: {formatter: (value) => { return value + ' πελάτες' }}},
              legend:{position: 'bottom'},
              stroke:{width:0},
              responsive: [{breakpoint: 480, options: {chart: {width: 220, height:300}, legend: {position: 'bottom'}}}]
          },
          availableYears: [],
          sexSeries: [0,0],
          customerSeries:{
              labels: null,
              series: [{
                  name: 'Αυτός ο μήνας',
                  data: []
              },
                  {name: 'Προηγούμενος μήνας',
                  data: []
              },],
              dietPlanSeries: [{
                  name: 'Αυτός ο μήνας',
                  data: []
              },
              {
                  name: 'Προηγούμενος μήνας',
                  data: []
              }]
          },
          seriesLoaded: false,
          statistics: {},
          lastUpdated: null,
          events:{
            loaded: false,
            key: 0,
            shortcuts: [
              {
                text: 'Αυτός ο μήνας',
                onClick: () => {
                  const currDate = new Date();
                  // return a Date
                  this.events.dateFrom = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
                  this.events.dateTo = new Date(currDate.getFullYear(), currDate.getMonth()+1, 1);
                },
              },
              {
                text: 'Τελευταίος μήνας',
                onClick: () => {
                  const currDate = new Date();
                  this.events.dateFrom = new Date(currDate.getFullYear(), currDate.getMonth()-1, 1);
                  this.events.dateTo = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
                  // return a Date;
                },
              },
            ],
            dateFrom: null,
            dateTo: null,
            dateRange: [null, null],
            data: {
              cancelled: {
                count: 0,
                sum: 0,
              },
              normal: {
                count: 0,
                sum: 0
              }
            }
          },
          baseCardData: {
            customerCount: 0,
            dpCount: 0,
            monthlyUntilToday: 0,
            expectedTillEndOfMonth: 0,
            activeEvents: 0,
            cancelledEvents: 0
          },
          financial: {
            loaded: false,
            selectedYear: null,
            selectedTab: 0

          },
          baseCards: [
            {
              title: 'Σύνολο πελατολογίου', key: 'customerCount',
              color: 'primary',
              size: 'col-span-6'
            },
            {
              title: 'Σύνολο διατροφικών πλάνων πελατών', key: 'dpCount',
              color: 'primary',
              size: 'col-span-6'
            },
          ],
          secondCards: [
            {
              title: 'Έσοδα του μήνα έως σήμερα', key: 'monthlyUntilToday',
              append: '€',
              color: 'success',
              size: 'col-span-3'

            },
            {
              title: 'Εκτιμώμενα έσοδα για τον υπόλοιπο μήνα', key: 'expectedTillEndOfMonth', append: '€', color:'dark',
              size: 'col-span-3'
            },
            {
              title: 'Ραντεβού αυτό τον μήνα', key: 'activeEvents', color:'success',
              size: 'col-span-3'
            },
            {
              title: 'Ακυρωμένα ραντεβού αυτό τον μήνα', key: 'cancelledEvents', color: 'danger',
              size: 'col-span-3'
            }
          ]
      }
  },

  created() {
      this.fetchStatistics().then(res => {
          this.getStatistics();
      });

      // initialize dateFrom, dateTo
    const currDate = new Date();

    for(let i=-10; i <5; i++){
      this.availableYears.push(currDate.getFullYear() + i);
    }
    this.financial.selectedYear = currDate.getFullYear();
    this.events.dateFrom = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
    this.events.dateTo = new Date(currDate.getFullYear(), currDate.getMonth()+1, 1);

    this.getEventStatistics();
    this.getYearlyFinancialAnalysis();


  },
  methods: {


      getEventStatistics(){

        this.events.loaded = false;

        // reset data!
        this.events.data.normal.count = 0;
        this.events.data.normal.sum = 0;
        this.events.data.cancelled.count = 0;
        this.events.data.cancelled.sum = 0;

        this.$axios.get('/statistics/events',
            {params: {
              dateFrom: this.events.dateFrom.toISOString().substring(0,10),
              dateTo: this.events.dateTo.toISOString().substring(0,10)}
            }).then(res=>{

              let normalEvents = res.data.find(x => !x.isCancelled);
              let cancelledEvents = res.data.find(x => x.isCancelled);

              if(normalEvents){
                this.events.data.normal.count = parseInt(normalEvents.count);
                this.events.data.normal.sum = parseFloat(normalEvents.sum);
              }
              if(cancelledEvents){
                this.events.data.cancelled.count = parseInt(cancelledEvents.count);
                this.events.data.cancelled.sum = parseFloat(cancelledEvents.sum);
              }

              this.events.loaded = true;
              this.events.key++;

        }).catch(e=>{
          this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
        })
      },

      getYearlyFinancialAnalysis(){

        Vue.set(this.financial, this.financial.selectedYear, {
          sum: [
              {name: 'Έσοδα από ραντεβού', data: []},
              {name: 'Ακυρωμένα έσοδα (ραντεβού που ακυρώθηκαν)', data: []},
          ],
            count:[
            {name: 'Ραντεβού', data: []},
            {name: 'Ακυρωμένα ραντεβού', data: []},
        ]});

        this.financial.loaded = false;

        this.$axios.get('/statistics/financial', {params: {year: this.financial.selectedYear}}).then(res=>{
          let data = res.data;
          for(let i=0; i<12;i++){
            let currentMonthOrders = data.filter(x => x.month === i+1);
            let completedEvents = {
              count: 0,
              sum: 0
            }
            let cancelledEvents = {
              count: 0,
              sum: 0
            }

            for(let item of currentMonthOrders){
              if(item.cancelled){
                cancelledEvents.count = parseInt(item.total);
                cancelledEvents.sum += parseFloat(item.sum);
              }else{
                completedEvents.count = parseInt(item.total);
                completedEvents.sum += parseFloat(item.sum);
              }

            }

            this.financial[this.financial.selectedYear]['sum'][0].data.push(
               completedEvents.sum.toFixed(2)
            )

            this.financial[this.financial.selectedYear]['count'][0].data.push(
                completedEvents.count
            )

            this.financial[this.financial.selectedYear]['sum'][1].data.push(
                cancelledEvents.sum.toFixed(2)
            )
            this.financial[this.financial.selectedYear]['count'][1].data.push(
                cancelledEvents.count
            )

          }
          this.financial.loaded = true;
        }).catch(e=>{
          this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
        })
      },
    getFinancialChartOptions(){
        let financialTab = this.financial.selectedTab;
      return {
        colors:['#3155A6','#dc2626'],
        chart:{toolbar: {show: false, tools: {download: false, selection: false, zoom: false, zoomin: false, zoomout: false, pan: false, reset:false, customIcons: []}},
          type: 'bar',  stacked: false,},
        plotOptions:{
          bar:{borderRadius: 10, dataLabels: {position: 'top'},},
          dataLabels: {
            enabled: false
          },
        },
        yaxis: {
          forceNiceScale: true
        },
        tooltip:{y: {formatter: (val, opts) =>  {
              if(financialTab === 0) return val + '€'
              return val
            }}},
        responsive: [
          {
            breakpoint: 540,
            options:{
              chart:{stacked: true},
              plotOptions:{ bar: {borderRadius:3}},
              dataLabels:{style:{fontSize:'5px'}}
            }
          }
        ],
        dataLabels: {
          formatter: (val, opts) =>  {
            if(financialTab === 0) return val + '€'
            return val
          },
          offsetY: 2,
          style:{
            fontSize: '10px',
            fontWeight: '300',
            fontFamily: 'inherit'
          }
        },
        xaxis: {
          categories: this.elDatePicker.months
        },
      }
    },
      getStatistics(){
          this.statistics = this.$store.state.athlete._athleteStatistics;
          this.lastUpdated = this.$store.state.athlete.lastUpdated;

          this.sexSeries[0] = this.statistics.athletes.maleCount;
          this.sexSeries[1] = this.statistics.athletes.femaleCount;
          this.customerSeries.series[0].data = this.statistics.athletes.newCount;
          this.customerSeries.series[1].data = this.statistics.athletes.lastMonthCount;
          this.customerSeries.dietPlanSeries[0].data = this.statistics.dietPlans.newCount;
          this.customerSeries.dietPlanSeries[1].data = this.statistics.dietPlans.lastMonthCount;
          this.baseCardData.dpCount = this.statistics.dietPlans.totalCount;
          this.baseCardData.monthlyUntilToday = this.statistics.earnings.monthlyUntilToday;
          this.baseCardData.expectedTillEndOfMonth = this.statistics.earnings.expectedTillEndOfMonth
          this.baseCardData.activeEvents = this.statistics.events.active;
          this.baseCardData.cancelledEvents = this.statistics.events.cancelled;
          this.baseCardData.customerCount = this.sexSeries[0] + this.sexSeries[1];
          this.customerSeries.labels = this.statistics.labels;
          this.seriesLoaded = true;
      },
      async fetchStatistics(){
          if(!this.$store.state.athlete._athleteStatistics || !localStorage.getItem('athletes.statistics')) {
              await this.$store.dispatch('athlete/cacheStatistics')
          }else{
              await this.$store.dispatch('athlete/refreshCacheStatistics');
          }
      },
      async reFetchStatistics(){
          this.seriesLoaded = false;
          this.$store.dispatch('athlete/cacheStatistics').then(res => {
              this.getStatistics();
              this.seriesLoaded=true;
          });

      },
      getOptions(type){
          let diagramOpt = {
              chart: {
                toolbar: {show: false, tools: {download: false, selection: false, zoom: false, zoomin: false, zoomout: false, pan: false, reset:false, customIcons: []}}
              },
              stroke:{width: 2, curve: 'smooth'},
              tooltip: {y:{}},
              xaxis: {categories: this.dayNames},
              yaxis: {forceNiceScale: true},
              animations: {enabled: false}
          };

          diagramOpt.chart.id = 'new customers';
          diagramOpt.tooltip.y = type==='customer' ? {formatter: (value) => { return value + ' πελάτες' }} : {formatter: (value) => { return value + ' διατροφικά πλάνα' }};
          diagramOpt.colors = type==='customer'? ['#3155A6', 'rgba(42, 158, 251, .2)'] : ['#1abc9c', 'rgba(10, 74, 61, .2)'];
          diagramOpt.xaxis.categories = this.customerSeries.labels;
          return diagramOpt;
      },

      getUpdatedDate(){
          return moment(this.lastUpdated).format('DD-MM-YYYY HH:mm');
      },

      getRangedStatsOptions(type){
        return {
          chart: {id: '13pie-'+type, height: 300, type: 'donut',},
          labels: type === 0 ? ['Ραντεβού αυτή την περίοδο', 'Ακυρωμένα ραντεβού'] : ['Έσοδα αυτή την περίοδο', 'Ακυρωμένα έσοδα αυτή την περίοδο'],
          colors:['#22c55e','#dc2626'],
          tooltip:{y: {formatter: (value) => { return type === 0 ? value + ' Ραντεβού' : value + '€' }}},
          legend:{position: 'bottom'},
          stroke:{width:0},
        }
      }
  },
}
</script>

<template>

  <div id="athlete-statistics" class="pb-5">
    <div class="text-right my-2">
      Τελευταία ενημέρωση: {{getUpdatedDate()}} <b-button variant="primary" class="ml-2" @click="reFetchStatistics"><span class="icon-refresh"></span></b-button>
    </div>


    <div class="grid grid-cols-12 gap-4">
      <b-card v-for="card of baseCards" :key="card.key" :class="card.size" class="mb-0 col-span-tablet-half col-span-mobile-full">
          <div class="text-center">
            <h2 class="font-weight-bolder" :style="`font-size:30px`" :class="`text-${card.color}`"><span> {{baseCardData[card.key]}} {{card.append}}</span></h2>
            <p class="text-muted" style="font-size:11px;">{{ card.title }}</p>
          </div>
      </b-card>
    </div>

    <div class="grid grid-cols-12 gap-4 mt-3">

        <b-card class="col-span-9 col-span-tablet-full">
          <b-card-title>

            <div class="row">
              <div class="col-12 col-md-4">
                Στατιστικά με ορισμένη περίοδο
              </div>
              <div class="col-12 col-md-8">
                <div style="width:500px;max-width:100%" class="ml-auto">
                  <div class="grid grid-cols-3 gap-3 align-items-center">
                    <label>
                      Από:
                      <date-picker v-model="events.dateFrom" :shortcuts="events.shortcuts" :first-day-of-week="1" :lang="elDatePicker"
                                   :not-after="events.dateTo"
                                   format="DD-MM-YYYY" placeholder="Ημερομηνία από"></date-picker>
                    </label>

                    <label>
                      Έως:
                      <date-picker v-model="events.dateTo" :shortcuts="events.shortcuts" :first-day-of-week="1" :lang="elDatePicker"
                                   :not-before="events.dateFrom"
                                   format="DD-MM-YYYY" placeholder="Ημερομηνία έως"></date-picker>
                    </label>

                    <b-button variant="primary" class="p-0" style="height:30px;" @click="getEventStatistics">Προβολή</b-button>

                  </div>
                </div>
              </div>
            </div>



          </b-card-title>

          <b-card-body style="min-height:300px;padding:15px 0!important;">

            <b-overlay :show="!events.loaded" rounded="sm">

              <div class="grid grid-cols-2 gap-5">
                <div>
                  <apexchart v-if="events.loaded"
                             :key="events.key"
                             height="300px" type="donut" :options="getRangedStatsOptions(1)"
                             :series="[events.data.normal.sum, events.data.cancelled.sum]"></apexchart>

                </div>
                <div>
                  <apexchart v-if="events.loaded"
                             :key="events.key"
                             height="300px" type="donut" :options="getRangedStatsOptions(0)" :series="[events.data.normal.count, events.data.cancelled.count]"></apexchart>

                </div>

              </div>


            </b-overlay>
          </b-card-body>

        </b-card>

        <b-card class="col-span-3 col-span-tablet-full">
          <b-card-title>Φύλο πελατών</b-card-title>
          <b-card-sub-title>Κατανομή του φύλου του πελατολογίου σας</b-card-sub-title>
          <b-card-body>
          </b-card-body>
          <apexchart v-if="seriesLoaded" height="300px" type="pie" :options="pieOptions" :series="sexSeries"></apexchart>
        </b-card>


    </div>


    <div class="grid grid-cols-12 gap-4 mb-3">
      <b-card v-for="card of secondCards" :key="card.key" :class="card.size" class="mb-0 col-span-tablet-half col-span-mobile-full">
        <div class="text-center">
          <h2 class="font-weight-bolder" :style="`font-size:30px`" :class="`text-${card.color}`"><span> {{baseCardData[card.key]}} {{card.append}}</span></h2>
          <p class="text-muted" style="font-size:11px;">{{ card.title }}</p>
        </div>
      </b-card>
    </div>

    <b-card style="min-height:350px">
      <b-overlay :show="!financial.loaded" rounded="sm">
      <b-card-title>
        <div class="grid grid-cols-12">
          <div class="col-span-8">
            Έσοδα έτους - {{financial.selectedYear}}
          </div>
          <div v-if="financial.loaded" class="col-span-4 text-right">
            <div class="w-auto">
              <b-form-select id="select-financial-year" v-model="financial.selectedYear"
                             style="width:fit-content;"
                             :options="availableYears" class="select-financial-year" @change="financial[financial.selectedYear] ? '' : getYearlyFinancialAnalysis()">
              </b-form-select>
            </div>


          </div>
        </div>
      </b-card-title>
      <b-card-sub-title>Τα έσοδα / ραντεβού του κάθε μήνα για το έτος {{financial.selectedYear}} (Σημείωση: για τους επόμενους μήνες, το διάγραμμα εμφανίζει τα κανονισμένα έσοδα / ραντεβού).</b-card-sub-title>
        <b-card-body style="padding:15px 0!important;">

          <b-tabs v-model="financial.selectedTab" lazy>
            <b-tab title="Ετήσια έσοδα">
              <apexchart v-if="financial.loaded" type="bar" height="350" :options="getFinancialChartOptions()" :series="financial[financial.selectedYear]['sum']"></apexchart>
            </b-tab>
            <b-tab title="Ραντεβού">
              <apexchart v-if="financial.loaded" type="bar" height="350" :options="getFinancialChartOptions()" :series="financial[financial.selectedYear]['count']"></apexchart>
            </b-tab>
          </b-tabs>

        </b-card-body>
       </b-overlay>
    </b-card>


    <div class="grid grid-cols-12 gap-5 mt-3">

      <b-card class="col-span-6 col-span-tablet-full"  style="min-height:350px">
        <b-overlay :show="!seriesLoaded" rounded="sm">
          <b-card-title>Δημιουργία διατροφικών πλάνων</b-card-title>
          <b-card-sub-title>Δείτε τον ρυθμό δημιουργίας νέων διατροφικών πλάνων σε σύγκριση με τον τελευταίο μήνα</b-card-sub-title>
          <b-card-body style="padding:15px 0!important;">
              <apexchart v-if="seriesLoaded" height="400px" type="line" :options="getOptions()" :series="customerSeries.dietPlanSeries" ></apexchart>
          </b-card-body>
        </b-overlay>

      </b-card>
      <b-card class="col-span-6 col-span-tablet-full"  style="min-height:350px">
        <b-overlay :show="!seriesLoaded" rounded="sm">
          <b-card-title>Νέοι πελάτες</b-card-title>
          <b-card-sub-title>Δείτε τον ρυθμό δημιουργίας πελατών σε σύγκριση με τον τελευταίο μήνα</b-card-sub-title>

          <b-card-body style="padding:15px 0!important;">
            <apexchart v-if="seriesLoaded" height="400px" type="line" :options="getOptions('customer')" :series="customerSeries.series" ></apexchart>

          </b-card-body>
        </b-overlay>
      </b-card>
    </div>

  </div>

</template>
